import React from "react";
import ReactClass from "create-react-class";
import PropTypes from "prop-types";
import Store from "store2";
import Config from "../../config";

const Storage = Store.namespace(Config.appnamespace);
const user = Storage.get("user");

const navItems = [
  {icon: "icon-activity", label: "CAT Projects", target: "/admin/configs/cat-project"},
  {icon: "icon-briefcase", label: "Dashboard Widgets", target: "/admin/configs/widget"},
  {icon: "icon-cog", label: "Custom Config", target: "/admin/configs"},
  {icon: "icon-alert-triangle", label: "System Outage", target: "/admin/configs/outage"}
];

export default ReactClass({
  displayName: "AdminNavbar",

  getInitialState() {
    return {
      items: [].concat(navItems)
    };
  },

  matchesPath(item) {
    const {route: {path}} = this.props, {activeWhen = []} = item;
    return path === item.target || activeWhen.some(reg => reg.test(path));
  },
  render() {
    const {application: app} = this.props,
        navItems = this.state.items,
        items = navItems.map((item, i) => {
          const itemClass = this.matchesPath(item) ? "item selected" : "item";
          return (
            <li title={item.label} key={item.label + i} className={itemClass} onClick={() => app.route(item.target)}>
              <i className={"icon " + item.icon} />
              <span className="label">{item.label}</span>
            </li>
          );
        });
    return (
      <ul className="navbar">
        {items}
      </ul>
    );
  }
});
