import AdminConfigView from "./AdminConfigView.jsx";
import CatConfigView from "./CatProjectConfig.jsx";
import WidgetConfigView from "./WidgetConfig.jsx";
import OutageConfigView from "./OutageConfig.jsx";

export default [
  {
    path: "/admin/configs",
    controller: context => {
      return {
        component: AdminConfigView,
        context
      };
    }
  },
  {
    path: "/admin/configs/cat-project",
    controller: context => {
      return {
        component: CatConfigView,
        context
      };
    }
  },
  {
    path: "/admin/configs/widget",
    controller: context => {
      return {
        component: WidgetConfigView,
        context
      };
    }
  },
  {
    path: "/admin/configs/outage",
    controller: context => {
      return {
        component: OutageConfigView,
        context
      };
    }
  }
];
